import { graphql } from "gatsby";
import React from "react";
import GenericHero from "../components/Shared/Generic/Hero/GenericHero";
import NotFound from "../components/Shared/NotFound/NotFound";
import { useLocale } from "../hooks";
import Seo from "../components/seoGlobal";
import Schema from "../components/schema";
import { getPathname } from "../utils/functions"

const ThankYou = ({
    location,
    pageContext,
    data: {
        contentfulPageCustom: {
            title,
            subtitle,
            heroImage,
            buttonLink,
            description,
            buttonLinkText,
            slug
        },
        allContentfulPageCustom: {
            nodes: allLinks
        }
    }
}) => {
    const lang = useLocale()
    return (
        <>
            <Seo title={title} description={subtitle} lang={lang} productImage={heroImage.fluid} hrefLangs={allLinks} />
            <Schema slug={getPathname(pageContext.node_locale,slug)}/>
            <GenericHero subtitle={subtitle} title={title} fluid={heroImage.fluid} firstChild={true} />
            <NotFound description={description} buttonLink="/" buttonLinkText={buttonLinkText} />
        </>
    )
}

export const PageThankYou = graphql`
    query pageThankYou($id: String!, $contentful_id: String!){
        contentfulPageCustom(id: { eq: $id }) {
            title
            slug
            subtitle
            description{
                description
            }
            heroImage {
                fluid {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
                title
            }
            buttonLinkText
            buttonLink
        }
        allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}, slug: {ne: null}}){
            nodes {
                slug
                node_locale
            }
        }
    }
`

export default ThankYou